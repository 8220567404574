<template>
  <div>
    <b-table :data="data" bordered narrowed>
      <b-table-column label="N°" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column field="nombre" label="Acción" v-slot="props">
        {{ props.row.nombre }}
      </b-table-column>
      <b-table-column field="descripcion" label="Descripción" v-slot="props">
        {{ props.row.descripcion }}
      </b-table-column>
      <b-table-column field="created_at" label="Fecha" v-slot="props">
        <b-field>
          <b-tag icon="times">{{ props.row.created_at }}</b-tag>
        </b-field>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered has-text-danger p-5">No hay actividades por el momento</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed:{
    ...mapGetters({
      data: 'auth/logs'
    }),
  }
};
</script>
