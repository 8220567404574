<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column">
        <input-base
          rules="required"
          label="Usuario"
          maxlength="50"
          placeholder="Usuario"
          v-model="form.username"
        />
      </div>
      <div class="column">
        <input-base
          rules="required|email"
          label="E-mail"
          maxlength="200"
          placeholder="E-mail"
          v-model="form.email"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules=""
          label="Contraseña Actual"
          maxlength="150"
          type="password"
          placeholder="Contraseña Actual"
          v-model="form.password_current"
        />
      </div>
      <div class="column">
        <input-base
          rules=""
          label="Contraseña Nueva"
          maxlength="150"
          type="password"
          placeholder="Contraseña Nueva"
          v-model="form.password"
        />
      </div>
    </div>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
    </div>
  </form-observer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed:{
    ...mapGetters({
      user: "auth/user"
    })
  },
  async mounted(){
    this.defaultData()
  },
  methods: {
    defaultData(){
      const {username,email} = this.user
      this.form = {username, email}
      this.$refs.observer.reset()
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`auth/ProfileRequest`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Acabamos de guardar sus datos.`
        );
        if(this.form.password) {
          this.$set(this.form, "password", "")
          this.$set(this.form, "password_current", "")
        }
      }
      this.loading = false;
      
    },
  },
};
</script>
