<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column">
        <input-base
          rules="numeric|digits:8"
          label="DNI"
          maxlength="8"
          placeholder="DNI"
          v-model="form.dni"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Nombres"
          maxlength="200"
          placeholder="Nombres"
          v-model="form.nombres"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Apellidos"
          maxlength="200"
          placeholder="Apellidos"
          v-model="form.apellidos"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="numeric|digits:11"
          label="RUC"
          maxlength="11"
          placeholder="RUC"
          v-model="form.ruc"
        />
      </div>
      <div class="column">
        <input-base
          rules="email"
          label="E-Mail"
          maxlength="60"
          placeholder="E-Mail"
          v-model="form.email"
        />
      </div>
      <div class="column">
        <input-base
          rules="numeric"
          label="Celular"
          maxlength="11"
          placeholder="Celular"
          v-model="form.celular"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="numeric"
          label="Teléfono"
          maxlength="11"
          placeholder="Teléfono"
          v-model="form.telefono"
        />
      </div>
      <div class="column">
        <input-base
          rules=""
          label="Dirección"
          maxlength="150"
          placeholder="Dirección"
          v-model="form.direccion"
        />
      </div>
      <div class="column">
        <select-search-uri
          rules=""
          label="Región / Provincia / Distrito" 
          uri="/ubigeo/select"
          v-model="form.ubigeo_id"
          expanded
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules=""
          label="Descripción"
          maxlength="250"
          placeholder="Descripción"
          v-model="form.descripcion"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <input-image-base
          rules=""
          label="Foto"
          placeholder="Foto"
          v-model="form.imagen_file"
          :src="user && user.persona.imagen_url"
          @remove="() => form.imagen_remove = true"
        />
      </div>
      <div class="column is-4">
        <radio-base
          rules=""
          label="Sexo"
          :options="[{id: 'M',value: 'Masculino'}, {id: 'F',value: 'Femenino'}]"
          v-model="form.sexo"
        />
      </div>
    </div>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
    </div>
  </form-observer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed:{
    ...mapGetters({
      user: "auth/user"
    })
  },
  async mounted(){
    this.defaultData()
  },
  methods: {
    defaultData(){
      this.form = {...this.user.persona}
      this.$refs.observer.reset()
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`auth/ProfileRequest`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Acabamos de guardar sus datos.`
        );
        this.form.imagen_file = null
      }
      this.loading = false;
      
    },
  },
};
</script>
